<template>
  <div>
    <!--  公司介绍-->
    <div id="introduce" class="main-box park-program">
      <el-col :span="24">
        <div class="main-description">公司介绍</div>
        <div class="park-program-minSpan">PROFILE</div>
      </el-col>
      <el-row type="flex" justify="center" :gutter="24">
        <img src="@/assets/地图.png" alt="" />
        <div style="width: 60px"></div>
        <div class="main-text-content">
          <span
            >北京高升数据系统有限公司成立于2015年，系高升控股股份有限公司（股票代码:000971.SZ）全资子公司。
            高升自2006年开始进入云基础服务领域，为广大政企单位及互联网客户提供从云到端的一站式综合云基础解决方案与服务集成，通过多年发展，高升围绕资源、应用、数据、服务四个方面，形成了
          </span>
          <span class="text-content-0">
            以“云网”资源为基础，以“数据+应用”赋能，以“行业数字化服务”为价值输出
          </span>
          <span> 的面向行业的 </span>
          <span class="text-content-0">“云+网+端”一体化服务能力。 </span>
          <span>
            高升积极响应政府数字经济“新基建”政策，致力于工业互联网综合服务提供商、区域云服务企业赋能平台提供商建设，并携手国家工业互联网平台航天云网进行工业互联网全国布局，联合打造
          </span>
          <span class="text-content-0"
            >4个核心节点+8个区域中心节点+N个边缘云站节点</span
          >
          <span>组成的可运营的工业互联网云平台。 </span>
          <div class="text-content-1">
            4个核心节点（北京、呼市、常州、深圳）
          </div>
          <div class="text-content-2">
            8个区域节点（成都、武汉、贵阳、西安、沈阳、长治、青岛、杭州）
          </div>
          <span>N个边缘云站节点</span>
        </div>
      </el-row>
    </div>
    <!-- 发展历程 -->
    <div id="experience" class="main-box park-program main-introduce-box">
      <el-col :span="24">
        <div class="main-description">发展历程</div>
        <div class="park-program-minSpan .park-program-minSpan-highlighted">
          HISTORY
        </div>
        <el-row type="flex" align="middle" :gutter="24">
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2006</span>
            <span class="text-0">年，</span>
            <span class="text-2">高升科技成立，</span>
            <span class="text-1"
              >在吉林省长春市成立吉林省高升科技有限公司；</span
            >
          </div>
        </el-row>
        <el-row
          style="margin-top: 40px"
          type="flex"
          align="middle"
          :gutter="24"
        >
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2015</span>
            <span class="text-0">年，</span>
            <span class="text-2">成立高升数据，</span>
            <span class="text-1"
              >高升科技与蓝鼎控股重组成功，蓝鼎控股更名为“高升控股有限公司”；</span
            >
          </div>
        </el-row>
        <el-row
          style="margin-top: 40px"
          type="flex"
          align="middle"
          :gutter="24"
        >
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2016</span>
            <span class="text-0">年，</span>
            <span class="text-2">收购上海莹悦网络，</span>
            <span class="text-1">实现在“管”层面布局，向上游延伸；</span>
          </div>
        </el-row>
        <el-row
          style="margin-top: 40px"
          type="flex"
          align="middle"
          :gutter="24"
        >
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2018</span>
            <span class="text-0">年，</span>
            <span class="text-2">收购华麒通信，</span>
            <span class="text-1"
              >实现互联网云基础平台战略向更底层的通信网络架构领域的延伸；</span
            >
          </div>
        </el-row>
        <el-row
          style="margin-top: 40px"
          type="flex"
          align="middle"
          :gutter="24"
        >
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2019</span>
            <span class="text-0">年，</span>
            <span class="text-2">布局混合云，</span>
            <span class="text-1"
              >成为阿里云精英合作伙伴，华为云伙伴能力中心及AWS 高级合作伙伴，
              持续布局多云、混合云战略；</span
            >
          </div>
        </el-row>
        <el-row
          style="margin-top: 40px"
          type="flex"
          align="middle"
          :gutter="24"
        >
          <img class="img text-box-spacing" src="@/assets/修饰.png" alt="" />
          <div class="text-conent">
            <span>2020</span>
            <span class="text-0">年，</span>
            <span class="text-2"
              >与国家工业互联网平台航天云网达成战略合作关系，</span
            >
            <span class="text-1"
              >2020年，与国家工业互联网平台航天云网达成战略合作关系，双方共同进军工业互联网行业，共赴新征程。</span
            >
          </div>
        </el-row>
      </el-col>
    </div>
    <!-- 核心优势 -->
    <div id="advantage" class="main-box park-program">
      <el-col :span="24">
        <div class="main-description">核心优势</div>
        <div class="park-program-minSpan">ADVANTAGE</div>
      </el-col>
      <div class="main-row">
        <div v-for="(item, index) in advantages" :key="index">
          <div class="item-box">
            <img :src="item.icon" alt="" />
            <div>
              {{ item.title }}
            </div>
            <div>
              {{ item.category }}
            </div>
            <div
              class="item-box-0"
              v-for="(item_c, index_c) in item.contents"
              :key="index_c"
            >
              {{ item_c }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div id="honor" class="main-box park-program main-introduce-box">
      <el-col :span="24">
        <div class="main-description">荣誉资质</div>
        <div class="park-program-minSpan park-program-minSpan-highlighted">
          HISTORY
        </div>
      </el-col>
      <img style="margin-top: 40px" src="@/assets/荣誉资质.png" alt="" />
    </div>

    <!--  战略伙伴-->
    <div id="partner" class="main-box park-program" style="padding-bottom: 0">
      <el-col :span="24">
        <div class="main-description">战略伙伴</div>
        <div class="park-program-minSpan">PARTNER</div>
      </el-col>
      <div class="main-col">
        <img style="margin-top: 60px" src="@/assets/战略伙伴.png" alt="" />
        <div class="main-col-text" style="margin-top: 20px">
          高升作为航天云网国家工业互联网平台数据中心与云运营平台战略级合作伙伴，
        </div>
        <div class="main-col-text" style="margin-bottom: 12px">
          承担航天云网全国4+8+N工业互联网平台的建设、运营与区域云服务商体系的建立。
        </div>
      </div>

      <img src="@/assets/Location.png" alt="" />
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus";

export default {
  name: "about",
  data() {
    return {
      advantages: [
        {
          icon: require("@/assets/遍布全国的基础设施.png"),
          title: "遍布全国的",
          category: "基础设施",
          contents: [
            "300+分布式IDC",
            "11T互联网带宽运营",
            "32000公里全网骨干网络覆盖",
            "109个城市的大容量IP通信服务平台",
            "4T+数据传送能力",
          ],
        },
        {
          icon: require("@/assets/丰富的行业生态资源.png"),
          title: "丰富的行业",
          category: "生态资源",
          contents: [
            "4+8+N工业互联网生态布局",
            "19个省、市区域工业互联网主平台",
            "优质工业生态应用提供者",
          ],
        },
        {
          icon: require("@/assets/自主研发的多行业产品体系.png"),
          title: "自主研发的",
          category: "多行业产品体系",
          contents: [
            "智能大数据决策平台",
            "云-端-应用全链路优化（APM）",
            "一体化混合云应用",
            "生命周期管理平台（ITOM）",
            "多云异构全面融合高升积云平台",
            "VPN/SD-WAN等丰富的网络覆盖产品",
          ],
        },
        {
          icon: require("@/assets/完善的产品周期服务.png"),
          title: "完善的产品",
          category: "周期服务",
          contents: [
            "专云专网一体化运营服务",
            "通信及信息领域全链条综合服务",
            "一站式资源及技术支撑服务",
          ],
        },
      ],
    };
  },
  created() {
    bus.$on("jump", (e) => {
      this.$el
        .querySelector("#"+e)
        .scrollIntoView({ behavior: "smooth" });
    });
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
/*内容盒子*/
.main-box {
  padding: 70px 0;
}
.main-col {
  align-content: center;
  text-align: center;
  .main-col-text {
    font-family: SourceHanSansCN-Bold;
    font-size: 16px;
    color: #2c68ee;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
  }
}
.main-description {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.park-program {
  .park-program-minSpan {
    font-family: SourceHanSansCN-Normal;
    font-size: 20px;
    color: #e7e7e7;
    letter-spacing: 3.1px;
    text-align: center;
    margin: 20px 0;
  }
}
.main-introduce-box {
  padding-bottom: 150px;
  background: rgb(245, 247, 253);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  .img {
    margin-left: 434px;
    width: 28px;
    height: 28px;
  }
  .text-box-spacing {
    margin-right: 40px;
  }
  .text-conent {
    height: 40px;
    font-family: DINAlternate-Bold;
    font-size: 32px;
    color: #2c68ee;
    .text-0 {
      font-family: SourceHanSansCN-Normal;
      color: #2c68ee;
      font-size: 16px;
    }
    .text-1 {
      font-family: SourceHanSansCN-Normal;
      font-size: 16px;

      color: #808080;
    }
    .text-2 {
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;

      color: #1c1c1c;
    }
  }
}
.main-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  .item-box {
    width: 276px;
    font-family: SourceHanSansCN-Bold;
    font-size: 24px;
    color: #2c68ee;
    text-align: center;
    line-height: 40px;
    .item-box-0 {
      font-family: SourceHanSansCN-Normal;
      font-size: 16px;
      color: #808080;
    }
  }
  .item-box:first-child {
    margin-left: 40px;
  }
  .item-box:last-child {
    margin-right: 0;
  }
}

.main-description:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  width: 12px;
  margin-right: 40px;
  background-color: #2965ed;
}
.main-description:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  background-color: #2965ed;
}
.main-text-content {
  width: 543px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 40px;
  font-family: SourceHanSansCN-Normal;
  color: #808080;

  .text-content-0 {
    font-family: SourceHanSansCN-Bold;

    color: #1c1c1c;
  }

  .text-content-1 {
    font-family: SourceHanSansCN-Bold;

    color: #ff4949;
  }
  .text-content-2 {
    font-family: SourceHanSansCN-Bold;

    color: #2c68ee;
  }
}
</style>